<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <v-form v-model="isValid">
                <v-container class="py-0">
                    <v-row>
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                v-model="editedItem.title.value"
                                class="purple-input"
                                :label="$t('admin.posts.titleAtt')"
                                :error-messages="editedItem.title.error"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.title.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                v-model="editedItem.title2.value"
                                class="purple-input"
                                :label="$t('admin.posts.titleAtt2')"
                                :error-messages="editedItem.title2.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.title2.error = ''"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-file-input
                                v-model="editedItem.img.value"
                                :label="$t('admin.posts.image')"
                                accept="image/*"
                                :error-messages="editedItem.img.error"
                                :rules="[rules.requiredAtLeastOne]"
                                :disabled="isOnlyView"
                                @change="preview_img"
                            />
                            <v-img
                                class="img_preview"
                                :src="editedItem.img.url"
                            />
                        </v-col>

                        <v-spacer/>

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-file-input
                                v-model="editedItem.img2.value"
                                :label="$t('admin.posts.image2')"
                                accept="image/*"
                                :error-messages="editedItem.img2.error"
                                :disabled="isOnlyView"
                                @change="preview_img2"
                            />
                            <v-img
                                class="img_preview2"
                                :src="editedItem.img2.url"
                            />
                        </v-col>

                        <v-spacer/>

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-text-field
                                v-model="editedItem.url.value"
                                class="purple-input"
                                :label="$t('admin.posts.url')"
                                :error-messages="editedItem.url.error"
                                :disabled="isOnlyView"
                                @keyup="editedItem.url.error = ''"
                            />
                        </v-col>

                        <v-col
                            sm="4"
                            md="3"
                            lg="2"
                        >
                            <v-switch
                                :disabled="isOnlyView"
                                v-model="editedItem.visualizePost"
                                inset
                                :label="$t('admin.posts.available')"
                            />

                        </v-col>

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >

                            <v-text-field
                                v-model="editedItem.order.value"
                                class="purple-input"
                                :label="$t('admin.posts.order')"
                                :error-messages="editedItem.order.error"
                                :rules="[validOrder]"
                                :disabled="isOnlyView || !editedItem.visualizePost"
                                @keyup="editedItem.order.error = ''"
                                type="number"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="5"
                            lg="4"
                            sm="6"
                        >
                            <v-select
                                v-model="editedItem.lang.value"
                                :error-messages="editedItem.lang.error"
                                :items="[{id: 'es', name: 'Español'}, {id: 'en', name: 'Ingles'}]"
                                attach
                                :label="$t('admin.posts.lang')"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @change="editedItem.lang.error = ''"
                            />

                            <!--                            <v-text-field
                                v-model="editedItem.lang.value"
                                class="purple-input"
                                :label="$t('admin.posts.lang')"
                                :error-messages="editedItem.lang.error"
                                :rules="[rules.required]"
                                :disabled="isOnlyView"
                                @keyup="editedItem.lang.error = ''"
                            />-->
                        </v-col>

                        <v-col
                            v-if="isOnlyView"
                            class="py-5"
                            cols="12"
                        >
                            <label>{{ $t('admin.posts.pageBody') }}</label>
                            <div
                                class="ql-editor"
                                v-html="editedItem.body.value"
                            />
                        </v-col>

                        <v-col
                            v-else
                            cols="12"
                        >
                            <div
                                class="text-h6 d-flex justify-space-between"
                            >
                                <!--                                <span>{{ $t(`admin.posts.${editorVisual ? 'editorVisual' : 'editorCode'}`) }}</span>-->
                                <v-switch
                                    v-model="editorVisual"
                                    inset
                                    :label="$t('admin.posts.editorVisual')"
                                />

                                <v-btn
                                    class="mx-2 pb-0 mb-0 mt-4"
                                    fab
                                    dark
                                    color="primary"
                                    small
                                    @click="restoreBody"
                                >
                                    <v-icon dark>
                                        mdi-undo
                                    </v-icon>
                                </v-btn>
                            </div>

                            <vue-editor
                                v-if="editorVisual"
                                v-model="editedItem.body.value"
                                :editor-options="editorSettings"
                            />
                            <v-textarea
                                v-else
                                name="input-7-1"
                                :value="editedItem.body.value"
                                outlined
                                @change="editorCodeChanged($event)"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer/>

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t('common.confirmDialogCancel') }}
            </v-btn>
            <v-btn
                v-if="!isOnlyView"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import {mapGetters} from 'vuex'
import {VueEditor, Quill} from "vue2-editor";
import {ImageDrop} from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import i18n from "@/lang/lang";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
    components: {VueEditor},
    data: function () {
        return {
            urlMain: '/posts',
            rules: {
                ...rules
            },
            item: null,
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            isOnlyView: false,

            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {}

                }
            },
            editorVisual: false
        }
    },
    computed: {
        formTitle() {
            if (this.item) {
                return this.isOnlyView ? this.$t('admin.posts.view') : this.$t('admin.posts.edit')
            } else return this.$t('admin.posts.new')
        },
        validOrder() {
            const number = this?.editedItem?.order?.value;
            return !(this?.editedItem?.visualizePost) || /^(\+|\d)[0-9]{0,}$/.test(number) || i18n.t("rules.number")
        }
        // ...mapGetters(['role', 'cities'])
    },
    created() {
        // console.log(this.item);
        /* console.log('this.$route.params')
        console.log(this.$route.params) */
    },
    /* watch: {
        dialog(val) {
            if (val) {
                this.editedItem = this.getEditedItem()
            }
        }
    }, */
    mounted() {
        this.item = this.$route.params.item ?? null;

        this.addTooltipToEditor();
    },
    methods: {
        getImageRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=posts/images/';
        },
        getEditedItem() {
            const item = this.$route.params.item ?? null;
            /* console.log('******* item **********')
            console.log(item) */

            /* const file = new File(
                [item.attributes.image],
                item.attributes.image,
                {
                    type: "text/plain"
                }
            ); */

            var empty = !item

            return {
                id: empty ? '' : item.id,
                title: {
                    value: empty ? '' : item.attributes.title,
                    error: ''
                },
                title2: {
                    value: empty ? '' : item.attributes.title2,
                    error: ''
                },
                body: {
                    value: empty ? '' : item.attributes.body,
                    error: ''
                },
                visualizePost: empty ? true : (item.attributes.order ?? false),
                order: {
                    value: empty ? '' : item.attributes.order,
                    error: ''
                },
                lang: {
                    value: empty ? '' : item.attributes.lang,
                    error: ''
                },
                img: {
                    value: empty || !item.attributes.image ? [] : new File(
                        [item.attributes.image],
                        item.attributes.image,
                        {
                            type: "text/plain"
                        }
                    ),
                    error: "",
                    url: empty || !item.attributes.image ? ""
                        : this.getImageRoute() + item.attributes.image
                },
                img2: {
                    value: empty || !item.attributes.image2 ? [] : new File(
                        [item.attributes.image2],
                        item.attributes.image2,
                        {
                            type: "text/plain"
                        }
                    ),
                    error: "",
                    url: empty || !item.attributes.image2 ? ""
                        : this.getImageRoute() + item.attributes.image2
                },
                url: {
                    value: empty ? '' : item.attributes.url,
                    error: ''
                }
            }
        },
        preview_img() {
            if (this.editedItem.img.value && !(this.editedItem.img.value instanceof Array)) {
                this.editedItem.img.url = URL.createObjectURL(
                    this.editedItem.img.value
                );
            } else {
                this.editedItem.img.url = null;
                this.editedItem.img.value = [];
            }
        },
        preview_img2() {
            if (this.editedItem.img2.value && !(this.editedItem.img2.value instanceof Array)) {
                this.editedItem.img2.url = URL.createObjectURL(
                    this.editedItem.img2.value
                );
            } else {
                this.editedItem.img2.url = null;
                this.editedItem.img2.value = [];
            }
        },
        initialize() {

        },
        async save() {
            const formData = new FormData();
            formData.append("title", this.editedItem.title.value);
            formData.append("title2", this.editedItem.title2.value ?? '');
            formData.append("body", this.editedItem.body.value ?? '');
            formData.append("lang", this.editedItem.lang.value);
            formData.append("image", this.editedItem.img.value);
            formData.append("image2", this.editedItem.img2.value);
            formData.append("url", this.editedItem.url.value);

            if(this.editedItem.visualizePost){
                formData.append("order", this.editedItem.order.value);
            }

            let urlAppend = ''
            let method = 'POST'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                method = 'PATCH'

                formData.append("_method", method);
            }

            /* console.log('********** formData ********')
            console.log(formData) */

            try {
                const result = await axios
                    .post(this.urlMain + urlAppend, formData, {
                        headers: {"Content-Type": "multipart/form-data"}
                    });

                /* const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" }

                }) */

                /* console.log('********** result ********')
                console.log(result) */

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.viewPost(result.data.post)

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                /* console.log('********** errors ********')
                console.log(error) */

                if (error?.response?.status === 422) {
                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer === '/data/attributes/title') {
                                this.editedItem.title.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/body') {
                                this.editedItem.body.error = e.detail
                            }
                        })
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        },
        viewPost(item) {
            this.isOnlyView = true;

            this.editedItem = {
                id: item.id,
                title: {
                    value: item.title,
                    error: ''
                },
                title2: {
                    value: item.title2,
                    error: ''
                },
                body: {
                    value: item.body,
                    error: ''
                },
                order: {
                    value: item.order,
                    error: ''
                },
                lang: {
                    value: item.lang,
                    error: ''
                },
                img: {
                    value: new File(
                        [item.image],
                        item.image,
                        {
                            type: "text/plain"
                        }
                    ),
                    error: "",
                    url: this.editedItem.img.url
                },
                img2: {
                    value: new File(
                        [item.image2],
                        item.image2,
                        {
                            type: "text/plain"
                        }
                    ),
                    error: "",
                    url: this.editedItem.img2.url
                },
                url: {
                    value: item.url,
                    error: ''
                }
            }
        },

        restoreBody() {
            try {
                this.editedItem.body.value = this.item.attributes.body
            } catch (e) {

            }
        },
        addTooltipToEditor() {
            const toolbarTooltips = {
                font: 'Select a font',
                size: 'Select a font size',
                header: 'Select the text style',
                bold: 'Bold',
                italic: 'Italic',
                underline: 'Underline',
                strike: 'Strikethrough',
                color: 'Select a text color',
                background: 'Select a background color',
                script: {
                    sub: 'Subscript',
                    super: 'Superscript'
                },
                list: {
                    ordered: 'Numbered list',
                    bullet: 'Bulleted list',
                    check: 'Checked list'
                },
                indent: {
                    '-1': 'Decrease indent',
                    '+1': 'Increase indent'
                },
                direction: {
                    rtl: 'Text direction (right to left | left to right)',
                    ltr: 'Text direction (left ro right | right to left)'
                },
                // align: 'Text alignment',
                align: {
                    default: 'Text alignment left',
                    center: 'Text alignment center',
                    justify: 'Text alignment justify',
                    right: 'Text alignment right'
                },
                blockquote: 'Blockquote',
                'code-block': 'Code block',
                link: 'Insert a link',
                image: 'Insert an image',
                video: 'Insert an video',
                formula: 'Insert a formula',
                clean: 'Clear format',
                'add-table': 'Add a new table',
                'table-row': 'Add a row to the selected table',
                'table-column': 'Add a column to the selected table',
                'remove-table': 'Remove selected table',
                help: 'Show help'
            };

            const lang = this.$store.getters['languages/getLang'];
            if (lang !== 'en') {
                Object.keys(toolbarTooltips).map(key => {
                    let value;
                    if (typeof toolbarTooltips[key] === 'string') {
                        value = 'editorActions.' + key;
                        toolbarTooltips[key] = this.$t(value);
                    } else {
                        Object.keys(toolbarTooltips[key]).map(subKey => {
                            value = 'editorActions.' + key + '.' + subKey;
                            toolbarTooltips[key][subKey] = this.$t(value);
                        });
                    }
                });
            }

            const showTooltip = (which, el) => {
                let tool;
                if (which === 'button') {
                    tool = el.className.replace('ql-', '');
                } else if (which === 'span') {
                    tool = el.className.replace('ql-', '');
                    tool = tool.substr(0, tool.indexOf(' '));
                }
                if (tool) {
                    // if element has value attribute.. handling is different
                    // buttons without value
                    if (el.value === '') {
                        if (toolbarTooltips[tool]) {
                            if (toolbarTooltips[tool].default) {
                                el.setAttribute('title', toolbarTooltips[tool].default);
                            } else {
                                el.setAttribute('title', toolbarTooltips[tool]);
                            }
                        }
                    } else if (typeof el.value !== 'undefined') { // buttons with value
                        if (toolbarTooltips[tool][el.value]) {
                            el.setAttribute('title', toolbarTooltips[tool][el.value]);
                        }
                    } else {
                        el.setAttribute('title', toolbarTooltips[tool]);
                    }// default
                }
            };

            const toolbarElement = document.querySelector('.ql-toolbar');
            if (toolbarElement) {
                const matchesButtons = toolbarElement.querySelectorAll('button');
                for (const el of matchesButtons) {
                    showTooltip('button', el);
                }
                // for submenus inside
                const matchesSpans = toolbarElement.querySelectorAll('.ql-toolbar > span > span');
                for (const el of matchesSpans) {
                    showTooltip('span', el);
                }
            }
        },
        editorCodeChanged(element) {
            this.editedItem.body.value = element;
        }
    }
}
</script>

<style>
/*iframe.ql-video {
  width: 500px !important;
  height: 500px !important;
}*/

.quill-editor iframe {
    pointer-events: none;
}

</style>
